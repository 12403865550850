@use 'sass:map';
@use 'sass:math';

@import './../node_modules/ag-grid-community/dist/styles/ag-grid.css';
@import './../node_modules/ag-grid-community/dist/styles/ag-theme-fresh.css';
@import './../node_modules/ag-grid-community/dist/styles/ag-theme-dark.css';
@import './styles/fonts';
@import './styles/theme';
@import './styles/mat-table';

/**
 * Global styles.
 */

body {
  margin: 0;
  scroll-behavior: smooth;
}

.table-property {
  tr td:first-child {
    width: 150px;
    font-weight: bold;
  }
}

[mat-card-avatar].user-avatar {
  width: 75px;
  height: 75px;
  border: 1px solid grey;
  background-image: url('/assets/images/man.jpg');
}

/**
 * App loading styles.
 */

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 70px;
  text-align: center;

  & > div {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;

    &:not(:last-child) {
      margin-right: 4px;
    }
  }

  & .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  & .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}

/**
 * Material sidenav overriden styles.
 */

mat-sidenav {
  min-width: 5%;
  max-width: 80%;
  overflow: auto;
}

mat-sidenav.vertical {
  top: auto;
  bottom: auto;
  min-width: 0;
  max-width: none;

  left: 0;
  right: 0;
  overflow: auto;
  height: auto;
  max-height: 80%;

  transform: translate3d(0, -100%, 0);

  &.mat-sidenav-closing {
    transform: translate3d(0, -100%, 0);
  }

  &.mat-sidenav-end {
    bottom: 0;
    transform: translate3d(0, 100%, 0);

    &.mat-sidenav-closing {
      transform: translate3d(0, 100%, 0);
    }
  }
}

/**
 * Material dialog overriden styles.
 */

mat-dialog-container.mat-dialog-container {
  padding: 0;
  overflow: hidden;
}

.cdk-overlay-pane {
  &.image-viewer,
  &.image-editor {
    position: relative !important;
  }

  @for $i from 1 to 6 {
    &.opacity-#{$i} {
      opacity: #{math.div($i, 6)};
      transition: opacity 300ms ease-out;
    }
  }
}

/**
 * Material mat-form-field overriden styles.
 */

mat-form-field {
  // Give some default margins.
  margin: 2px 8px;

  .mat-form-field-wrapper {
    // Make shorter and denser by reducing bottom "gap".
    padding-bottom: 1em !important;
    .mat-form-field-underline {
      bottom: 1em;
    }

    // Reduce the default infix width.
    .mat-form-field-infix {
      width: 150px;
    }
  }
}

// Give some default margins for custom datetimepicker
.app-datetimepicker {
  margin: 2px 8px;
}

/**
 * Material mat-input textarea overriden styles allowing full height textarea.
 */

mat-form-field.full-height-textarea {
  height: 180px;
  min-height: 180px;

  .mat-form-field-wrapper {
    height: calc(100% - 16px);

    .mat-form-field-flex {
      height: 100%;

      .mat-form-field-infix {
        height: calc(100% - 28px);

        textarea {
          height: 100%;
        }
      }
    }
  }
}

/**
 * Material MatSelect overriden styles.
 */

mat-select {
  // Enable user to select displayed text, especially when disabled.
  .mat-select-value-text {
    user-select: all;
  }
}

/**
 * Material MatSort overriden styles.
 */

.mat-sort-header-content {
  text-align: left !important;
}

/**
 * Material misc overriden styles.
 */

// Set mat-error font size to be like when it's used inside mat-form-field. Used for data list's error message.
mat-error {
  font-size: 75%;
}

// Make all button text uppercase by default.
button:not(.mat-menu-item) {
  text-transform: uppercase;
}

/**
 * AG Grid overriden styles.
 */

.ag-theme-fresh,
.ag-theme-dark {
  &,
  .ag-header {
    font-family: inherit;
    font-size: inherit;
  }

  .ag-cell,
  .ag-header-cell,
  .ag-header-group-cell {
    line-height: 28px;
  }

  .ag-cell-inline-editing,
  // Plus sign and group count on group column.
  .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
    height: 28px;
  }

  input,
  textarea,
  select {
    font-family: inherit;
    font-size: rem(1.6);
    margin: 0;
    border: 0;
    padding: 0;
    outline: none;
  }
}
