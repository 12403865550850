@use 'sass:map';
@use 'sass:color';
@use '~@angular/material' as mat;

@import './mat-theme';

@mixin _ag-grid-theme($theme) {
  .ag-theme-fresh,
  .ag-theme-dark {
    .ag-header-row {
      background: mat.get-color-from-palette(map.get($theme, primary));
      color: mat.get-color-from-palette(map.get($theme, primary), default-contrast);
    }

    .ag-overlay-wrapper {
      span {
        padding: 10px;
      }
    }

    @if map.get($theme, is-dark) {
      .ag-cell-focus {
        background-color: color.scale(mat.get-color-from-palette(map.get($theme, primary), 100), $lightness: -25%);
        color: mat.get-color-from-palette(map.get($theme, primary), default-contrast);
        border: 1px solid mat.get-color-from-palette(map.get($theme, primary), 900) !important;
      }

      .ag-row-selected {
        background-color: color.scale(mat.get-color-from-palette(map.get($theme, primary), 100), $lightness: -40%);
        color: mat.get-color-from-palette(map.get($theme, primary), default-contrast);
      }

      .ag-overlay-wrapper {
        span {
          color: mat.get-color-from-palette(map.get($theme, foreground), text);
          border: 1px solid mat.get-color-from-palette(map.get($theme, foreground), divider);
          background-color: mat.get-color-from-palette(map.get($theme, background), background);
        }
      }

      .ag-overlay-loading-wrapper {
        background-color: mat.get-color-from-palette(map.get($theme, background), background, 0.5);
      }

      textarea {
        background-color: #302e2e;
        color: #ccc;
      }

      .ag-row:not(.ag-row-selected):not(.ag-row-hover) {
        &.ag-row-odd .highlight:not(.ag-cell-focus):not(.ag-cell-range-selected) {
          background-color: mat.get-color-from-palette(map.get($theme, primary), 900);
        }
        &.ag-row-even .highlight:not(.ag-cell-focus):not(.ag-cell-range-selected) {
          background-color: color.scale(mat.get-color-from-palette(map.get($theme, primary), 900), $lightness: -30%);
        }
      }

      .ag-row-hover:not(.ag-row-selected) {
        .ag-cell.ag-cell-range-selected {
          background-color: rgba(100, 160, 160, 0.2);
        }
        .ag-cell:not(.ag-cell-range-selected) {
          background-color: color.scale(mat.get-color-from-palette(map.get($theme, primary), 100), $lightness: -70%);
        }
      }
    } @else {
      .ag-cell-focus {
        background-color: color.scale(mat.get-color-from-palette(map.get($theme, primary), 200), $lightness: -15%);
        border: 1px solid mat.get-color-from-palette(map.get($theme, primary), 600) !important;
      }

      .ag-row-selected {
        background-color: mat.get-color-from-palette(map.get($theme, primary), 200);
      }

      .ag-overlay-wrapper {
        span {
          color: mat.get-color-from-palette(map.get($theme, foreground), text);
          border: 1px solid mat.get-color-from-palette(map.get($theme, foreground), divider);
          background-color: mat.get-color-from-palette(map.get($theme, background), background);
        }
      }

      .ag-overlay-loading-wrapper {
        background-color: mat.get-color-from-palette(map.get($theme, background), background, 0.5);
      }

      .ag-row:not(.ag-row-selected):not(.ag-row-hover) {
        &.ag-row-odd .highlight:not(.ag-cell-focus):not(.ag-cell-range-selected) {
          background-color: color.scale(mat.get-color-from-palette(map.get($theme, primary), 50), $lightness: -10%);
        }
        &.ag-row-even .highlight:not(.ag-cell-focus):not(.ag-cell-range-selected) {
          background-color: mat.get-color-from-palette(map.get($theme, primary), 50);
        }
      }

      .ag-row-hover:not(.ag-row-selected) {
        .ag-cell.ag-cell-range-selected {
          background-color: rgba(120, 120, 120, 0.2);
        }
        .ag-cell:not(.ag-cell-range-selected) {
          background-color: color.scale(mat.get-color-from-palette(map.get($theme, primary), 200), $lightness: 50%);
        }
      }
    }
  }
}

@mixin _highlighted-form-field-theme($theme) {
  // mat-form-field with `fill` appearance.
  mat-form-field.mat-form-field-appearance-fill {
    // Use primary color if enabled.
    &:not(.mat-form-field-disabled) .mat-form-field-flex {
      background-color: mat.get-color-from-palette(map.get($theme, primary), 50) !important;
    }
    @if map.get($theme, is-dark) {
      &:not(.mat-form-field-disabled) .mat-form-field-flex {
        background-color: mat.get-color-from-palette(map.get($theme, primary), 900) !important;
      }
    }

    // Use warn color if error for both enabled and disabled.
    &.highlight-error {
      .mat-form-field-flex {
        background-color: mat.get-color-from-palette(map.get($theme, warn), 50) !important;
      }
      @if map.get($theme, is-dark) {
        .mat-form-field-flex {
          background-color: color.scale(
            mat.get-color-from-palette(map.get($theme, warn), 900),
            $lightness: -45%
          ) !important;
        }
      }
    }
  }

  // mat-hint used as mat-error on all appearances.
  mat-form-field.highlight-error {
    .mat-hint {
      color: mat.get-color-from-palette(map.get($theme, warn)) !important;
    }
    @if map.get($theme, is-dark) {
      .mat-hint {
        color: lighten(mat.get-color-from-palette(map.get($theme, warn)), 3) !important;
      }
    }
  }

  // Data List highlighted with red box.
  app-data-list.highlight-error {
    ag-grid-angular {
      border: 2px solid mat.get-color-from-palette(map.get($theme, warn), 500);
      box-sizing: border-box;
    }
    @if map.get($theme, is-dark) {
      ag-grid-angular {
        border: 2px solid color.scale(mat.get-color-from-palette(map.get($theme, warn), 900), $lightness: -45%);
        box-sizing: border-box;
      }
    }
  }
}

@mixin _misc-theme($theme) {
  // Anchor elament.
  a {
    text-decoration: none;
    color: mat.get-color-from-palette(map.get($theme, accent));

    &:hover {
      color: mat.get-color-from-palette(map.get($theme, accent), 900);
    }
  }

  // Spinner.
  .spinner {
    & > div {
      background-color: mat.get-color-from-palette(map.get($theme, primary));
    }
  }

  // Sidenav.
  #sidenav {
    #profile {
      @if map.get($theme, is-dark) {
        background: mat.get-color-from-palette(map.get($theme, primary), 400);
      } @else {
        background: mat.get-color-from-palette(map.get($theme, primary), 300);
      }
    }
    #menu {
      mat-icon {
        color: mat.get-color-from-palette(map.get($theme, foreground), text, 0.7);
      }

      a.mat-list-item.active,
      a.mat-list-item.active mat-icon {
        color: mat.get-color-from-palette(map.get($theme, primary));
      }
    }
  }

  // Scrollbar.
  @if map.get($theme, is-dark) {
    *::-webkit-scrollbar {
      background-color: mat.get-color-from-palette(map.get($theme, background), background);
    }
    *::-webkit-scrollbar-corner {
      background-color: mat.get-color-from-palette(map.get($theme, background), background);
    }
    *::-webkit-scrollbar-thumb {
      border: 1px
        solid
        color.scale(mat.get-color-from-palette(map.get($theme, background), raised-button), $lightness: -15%);
      background-color: mat.get-color-from-palette(map.get($theme, background), raised-button);
    }
  }

  // Dialog.
  .mat-dialog-container {
    background-color: mat.get-color-from-palette(map.get($theme, background), background);
    color: mat.get-color-from-palette(map.get($theme, foreground), text);
  }

  // Prevent default behavior of dimming text color of disabled inputs.
  .mat-input-element:disabled,
  .mat-select-disabled .mat-select-value,
  .mat-checkbox-disabled .mat-checkbox-label {
    color: mat.get-color-from-palette(map.get($theme, foreground), text);
  }

  // Dimmed text color.
  .dimmed {
    color: mat.get-color-from-palette(map.get($theme, foreground), disabled) !important;
    a {
      color: mat.get-color-from-palette(map.get($theme, accent), 0.5) !important;
    }
  }

  // mat-icon-button suffix toggle color.
  .mat-icon-button[matsuffix] {
    color: mat.get-color-from-palette(map.get($theme, foreground), icon);
  }
}

/**
 * Build material custom theme.
 */

@include mat.core();
@include mat.all-component-themes($light-theme);

@include _ag-grid-theme($light-theme);
@include _highlighted-form-field-theme($light-theme);
@include _misc-theme($light-theme);

.dark-theme {
  @include mat.all-component-colors($dark-theme);

  @include _ag-grid-theme($dark-theme);
  @include _highlighted-form-field-theme($dark-theme);
  @include _misc-theme($dark-theme);
}
